import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// local imports
import ApiAgent from '../../utils/apiAgent';
import {
  networkErrorMessageObjFactory,
  FormFeedback,
} from '../../utils/userFeedback';
import login from '../../redux/actions/user/login';
import { usernameValidator, passwordValidator } from '../form-validators';
import { FormFloatingInput } from '../form-components';
import resetNext from '../../redux/actions/app/resetNext';

const formStyle = { maxWidth: 650, width: '100%' };

const useStyles = makeStyles(
  theme => ({
    buttonDiv: {
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2),
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

/**
 * LoginForm
 * @returns LoginForm Component
 */

function LoginForm() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formFeedback, setFormFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const next = useSelector(state => state.app.next);

  const handleSubmit = useCallback(
    async formData => {
      const { username, password } = formData;
      setLoading(true);
      const resp = await ApiAgent.login({ username, password });
      setLoading(false);
      if (resp.error) {
        setFormFeedback(networkErrorMessageObjFactory(resp.error, true));
      } else {
        dispatch(login(resp.token, resp.user));
        if (next) {
          history.push(next);
          dispatch(resetNext());
        }
      }
    },
    [dispatch, history, next]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      render={formRenderProps => (
        <FormElement style={formStyle}>
          <fieldset className={'k-form-fieldset'}>
            {formFeedback && <FormFeedback feedback={formFeedback} />}
            <Box mb={2}>
              <Field
                name={'username'}
                id={'username'}
                component={FormFloatingInput}
                label={'Username'}
                validator={usernameValidator}
                autoComplete={'username'}
              />
            </Box>

            <Box mb={2}>
              <Field
                name={'password'}
                id={'password'}
                component={FormFloatingInput}
                label={'Password'}
                validator={passwordValidator}
                type={'password'}
                autoComplete={'current-password'}
              />
            </Box>
          </fieldset>
          <Box
            className={classes.buttonDiv}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.loginButton}
              disabled={!formRenderProps.allowSubmit || loading}
            >
              Login
            </Button>
          </Box>
        </FormElement>
      )}
    />
  );
}

export default LoginForm;
